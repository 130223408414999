// 引入接口
import { Module } from 'vuex'
import { IRootState } from '@/store/types'
import { ILoginState } from '@/store/login/types'
import { IAccount } from '@/service/api/login/types'
import localCatch from '@/utils/catch'
import router from '@/router/index'
import { mapMenusToPermissions, mapMenusToRoutes } from '@/utils/map-menus'

// 登录接口
import {
  accountLoginRequest,
  requestUserInfo,
  requestUserMenus
} from '@/service/api/login/login'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state: () => {
    return {
      token: '',
      userInfo: {},
      userMenus: [],
      permissions: []
    }
  },
  mutations: {
    // 改变 token 信息
    changeToken(state, token: string) {
      state.token = token
    },
    // 改变 用户 信息
    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },
    // 改变 菜单
    changeUserMenus(state, userMenus: any) {
      state.userMenus = userMenus

      const routes = mapMenusToRoutes(userMenus)
      // 2.添加到 main router里面 调用 addRoute 方法动态添加
      routes.forEach((route) => {
        router.addRoute('main', route)
      })

      // 3.获取用户权限
      const permissions = mapMenusToPermissions(userMenus)
      state.permissions = permissions
    }
  },
  getters: {},
  actions: {
    // 1.账号密码登录
    async accountLoginActive({ commit, dispatch }, payload: IAccount) {
      // 登录
      const loginResult = await accountLoginRequest(payload)
      const { id, token } = loginResult.data
      commit('changeToken', token)
      localCatch.setCatch('token', token)
      // 获取所有数据
      dispatch('getInitialDataAction', null, { root: true })

      // 请求用户信息
      const userInfoResult = await requestUserInfo(id)
      const userInfo = userInfoResult.data
      commit('changeUserInfo', userInfo)
      localCatch.setCatch('userInfo', userInfo)

      // 3.获取菜单
      const userMenusRequest = await requestUserMenus(userInfo.role.id)
      const userMenus = userMenusRequest.data
      commit('changeUserMenus', userMenus)
      localCatch.setCatch('userMenus', userMenus)
      // 4.跳转到首页
      router.push('/main')
    },

    // 2.记住登录数据 (防止刷新清除 vuex 数据)
    loadLocalLogin({ commit, dispatch }) {
      const token = localCatch.getCatch('token')
      if (token) {
        commit('changeToken', token)
        dispatch('getInitialDataAction', null, { root: true })
      }
      const userInfo = localCatch.getCatch('userInfo')
      if (userInfo) {
        commit('changeUserInfo', userInfo)
      }
      const userMenus = localCatch.getCatch('userMenus')
      if (userMenus) {
        commit('changeUserMenus', userMenus)
      }
    }
  }
}

export default loginModule
