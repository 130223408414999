// 动态生成路由
import { RouteRecordRaw } from 'vue-router'
import { IBreadcrumb } from '@/base-ui/breadcrumb'

// 默认打开的 页面
let firstMenu: any = null

/***
 * 动态生成路由
 * @param userMenus 菜单数组
 */
export function mapMenusToRoutes(userMenus: any[]): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = []

  // 1.先加载默认所有的路由 routes
  const allRoutes: RouteRecordRaw[] = []
  // 拿到所有 路由导出
  const routeFiles = require.context('../router/main', true, /\.ts/)
  routeFiles.keys().forEach((key) => {
    const route = require('../router/main' + key.split('.')[1])
    allRoutes.push(route.default)
  })

  // 2.根据菜单的需求添加 routes
  const _recurseGetRoute = (menus: any[]) => {
    for (const menu of menus) {
      if (Number(menu.type) === 2) {
        const route = allRoutes.find((route) => route.path === menu.url)
        if (route) routes.push(route)
        if (!firstMenu) {
          firstMenu = menu
        }
      } else {
        _recurseGetRoute(menu.children)
      }
    }
  }

  _recurseGetRoute(userMenus)

  return routes
}

/**
 * 拿到当前路由的路由名称
 * @param userMenus 菜单数组
 * @param currentPath 当前路由地址
 */
export function pathMapBreadcrumb(userMenus: any, currentPath: string) {
  const breadcrumb: IBreadcrumb[] = []

  pathMapMenu(userMenus, currentPath, breadcrumb)

  return breadcrumb
}

export function pathMapMenu(
  userMenus: any[],
  currentPath: string,
  breadcrumb?: IBreadcrumb[]
): any {
  for (const userMenu of userMenus) {
    if (Number(userMenu.type) === 1) {
      const findMenu = pathMapMenu(userMenu.children ?? [], currentPath)
      if (findMenu) {
        breadcrumb?.push({ name: userMenu.name })
        breadcrumb?.push({ name: findMenu.name })
        return findMenu
      }
    } else if (Number(userMenu.type) === 2 && userMenu.url === currentPath) {
      return userMenu
    }
  }
}

/***
 * 获取权健
 * @param userMenus 菜单
 */
export function mapMenusToPermissions(userMenus: any[]) {
  const permissions: string[] = []

  const _recurseGetPermission = (menus: any[]) => {
    for (const menu of menus) {
      if (Number(menu.type) === 1 || Number(menu.type) === 2) {
        _recurseGetPermission(menu.children ?? [])
      } else if (Number(menu.type) === 3) {
        permissions.push(menu.permission)
      }
    }
  }
  _recurseGetPermission(userMenus)

  return permissions
}

/***
 * 获取菜单 id 返回一个 id 的数组
 * @param menuList 菜单
 */
export function menuMapLeafKeys(menuList: any[]) {
  const leftKeys: number[] = []

  const _recurseGetLeaf = (menuList: any[]) => {
    for (const menu of menuList) {
      if (menu.children) {
        _recurseGetLeaf(menu.children)
      } else {
        leftKeys.push(menu.id)
      }
    }
  }
  _recurseGetLeaf(menuList)

  return leftKeys
}

export { firstMenu }
