import xmRequest from '@/service/index'
import { IAccount, ILoginResult } from './types'
import { IDataType } from '@/service/typed'
// 枚举
enum LoginApi {
  AccountLogin = '/login',
  loginUserInfo = '/users/',
  userMenus = '/role/' // => /role/4/menuIds
}
// 登录接口
export function accountLoginRequest(account: IAccount) {
  return xmRequest.post<IDataType<ILoginResult>>({
    url: LoginApi.AccountLogin,
    data: account
  })
}

// 获取用户信息
export function requestUserInfo(id: number) {
  return xmRequest.get<IDataType>({
    url: LoginApi.loginUserInfo + id,
    showLoading: false
  })
}

// 获取菜单
export function requestUserMenus(id: number) {
  return xmRequest.get<IDataType>({
    url: LoginApi.userMenus + id + '/menu',
    showLoading: false
  })
}
