import { Module } from 'vuex'
import { ISystemState } from '@/store/main/system/type'
import { IRootState } from '@/store/types'
import {
  createPageData,
  deletePageData,
  editPageData,
  getPageListData,
  editPageEnable
} from '@/service/api/main/system/system'

const systemModule: Module<ISystemState, IRootState> = {
  namespaced: true,
  state() {
    return {
      usersList: [],
      usersCount: 0,
      roleList: [],
      roleCount: 0,
      goodsList: [],
      goodsCount: 0,
      menuList: [],
      menuCount: 0,
      departmentList: [],
      departmentCount: 0
    }
  },
  mutations: {
    /* 用户列表 */
    changeUsersList(state, userList: any[]) {
      state.usersList = userList
    },
    /* 用户列表总数 */
    changeUsersCount(state, userCount: number) {
      state.usersCount = userCount
    },
    /* 角色列表 */
    changeRoleList(state, roleList: any[]) {
      state.roleList = roleList
    },
    /* 角色列表总数 */
    changeRoleCount(state, roleCount: number) {
      state.roleCount = roleCount
    },
    /* 商品列表 */
    changeGoodsList(state, goodsList: any[]) {
      state.goodsList = goodsList
    },
    /* 商品列表 总数 */
    changeGoodsCount(state, goodsCount: number) {
      state.goodsCount = goodsCount
    },
    /* 菜单 列表 */
    changeMenuList(state, menuList: any[]) {
      state.menuList = menuList
    },
    /* 菜单 列表 总数 */
    changeMenuCount(state, menuCount: number) {
      state.menuCount = menuCount
    },
    /* 部门 列表 */
    changeDepartmentList(state, menuList: any[]) {
      state.departmentList = menuList
    },
    /* 菜单 列表 总数 */
    changeDepartmentCount(state, menuCount: number) {
      state.departmentCount = menuCount
    }
  },
  getters: {
    // 数据源
    pageListData(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}List`]
      }
    },
    // 数据总条数
    pageListCount(state) {
      return (pageName: string) => {
        return (state as any)[`${pageName}Count`]
      }
    }
  },
  actions: {
    // 列表数据
    async getPageListAction({ commit }, payload: any) {
      const pageName = payload.pageName
      const pageUrl = `/${pageName}/list`

      const changePageName =
        pageName.slice(0, 1).toUpperCase() + pageName.slice(1)
      const pageRequest = await getPageListData(pageUrl, payload.queryInfo)
      const { list, totalCount } = pageRequest.data
      commit(`change${changePageName}List`, list)
      commit(`change${changePageName}Count`, totalCount)
    },
    // 删除数据
    async deletePageDataAction({ dispatch }, payload: any) {
      // pageName => 页面名称
      const { pageName, id } = payload
      const pageUrl = `/${pageName}/${id}`
      await deletePageData(pageUrl)
      dispatch('getPageListAction', {
        pageName,
        offset: 0,
        size: 10
      })
    },
    // 新增数据
    async createPageAction({ dispatch }, payload: any) {
      const { pageName, newData } = payload
      const pageUrl = `/${pageName}`
      await createPageData(pageUrl, newData)
      // 请求新数据
      dispatch('getPageListAction', {
        pageName,
        offset: 0,
        size: 10
      })
    },
    // 修改数据
    async editPageAction({ dispatch }, payload: any) {
      const { pageName, editData, id } = payload
      const pageUrl = `/${pageName}/${id}`
      await editPageData(pageUrl, editData)
      // 请求新数据
      dispatch('getPageListAction', {
        pageName,
        offset: 0,
        size: 10
      })
    },
    // 修改状态
    async editEnable({ dispatch }, payload: any) {
      const { pageName, enable, id } = payload
      const pageUrl = `/${pageName}/enable/${id}`
      await editPageEnable(pageUrl, { enable })
      // 请求新数据
      dispatch('getPageListAction', {
        pageName,
        offset: 0,
        size: 10
      })
    }
  }
}

export default systemModule
