import { createApp } from 'vue'
import App from './App.vue'
// 引入路由 vuex
import router from './router'
import store from '@/store'
// 引入 ui 组件库
import { globalRegister } from './global'
// 引入 css 样式
import 'normalize.css'
import './assets/css/index.less'
import { setupStore } from '@/store'

const app = createApp(App)
// 注册
app.use(globalRegister)
app.use(store)
setupStore()
app.use(router)

app.mount('#app')
