import { createStore, Store, useStore as useVuexStore } from 'vuex'
// 引入根 接口
import { IRootState, IStore } from '@/store/types'
// 引入模块
import login from './login/login'
import system from './main/system/system'
// 引入接口
import { getPageListData } from '@/service/api/main/system/system'

const store = createStore<IRootState>({
  state: () => {
    return {
      name: 'xiaomingzai',
      age: 25,
      entireDepartment: [],
      entireRole: [],
      entireMenu: []
    }
  },
  mutations: {
    changeEntireDepartment(state, list: any) {
      state.entireDepartment = list
    },
    changeEntireRole(state, list: any) {
      state.entireRole = list
    },
    changeEntireMenu(state, list: any) {
      state.entireMenu = list
    }
  },
  getters: {},
  actions: {
    // 获取 所有数据
    async getInitialDataAction({ commit }) {
      const departmentList = await getPageListData('/department/list', {
        offset: 0,
        size: 1000
      })
      const { list: entireDepartment } = departmentList.data

      const entireRoleList = await getPageListData('/role/list', {
        offset: 0,
        size: 1000
      })
      const { list: entireRole } = entireRoleList.data

      const menuResult = await getPageListData('/menu/list', {})
      const { list: menuList } = menuResult.data

      // 保存数组
      commit('changeEntireDepartment', entireDepartment)
      commit('changeEntireRole', entireRole)
      commit('changeEntireMenu', menuList)
    }
  },
  modules: {
    login,
    system
  }
})

// 记住登录数据 (防止刷新清除 vuex 数据)
export function setupStore() {
  store.dispatch('login/loadLocalLogin')
}

export function useStore(): Store<IStore> {
  return useVuexStore()
}

export default store
