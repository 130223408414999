class localCatch {
  /**
   * 设置缓存
   * @param key 键
   * @param value 字符串的值
   */
  setCatch(key: string, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  /***
   * 获取缓存
   * @param key 键
   */
  getCatch(key: string) {
    const val = window.localStorage.getItem(key)
    if (val) {
      return JSON.parse(val)
    }
  }

  /***
   * 删除缓存
   * @param key 键
   */
  deleteCatch(key: string) {
    window.localStorage.removeItem(key)
  }

  /***
   * 清除所有缓存
   */
  clearCatch() {
    window.localStorage.clear()
  }
}

export default new localCatch()
