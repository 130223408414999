import xmRequest from '@/service'
import { IDataType } from '@/service/typed'

/***
 * 获取列表
 * @param url: /user/list
 * @param queryInfo: 传入的参数
 */
export function getPageListData(url: string, queryInfo: any) {
  return xmRequest.post<IDataType>({
    url: url,
    data: queryInfo
  })
}

/***
 *  删除
 * @param url url: /user/id
 */
export function deletePageData(url: string) {
  return xmRequest.delete<IDataType>({ url: url })
}

/***
 *
 * @param url url: /user
 * @param newData 传入的数据
 */
export function createPageData(url: string, newData: any) {
  return xmRequest.post<IDataType>({ url: url, data: newData })
}

/***
 *
 * @param url url: /user/id
 * @param editData 传入的数据
 */
export function editPageData(url: string, editData: any) {
  return xmRequest.patch<IDataType>({ url: url, data: editData })
}

/***
 *
 * @param url url: /user/id
 * @param editData 传入的数据
 */
export function editPageEnable(url: string, enable: any) {
  return xmRequest.patch<IDataType>({ url: url, data: enable })
}
